<template>
  <div
    v-if="event && event.name && event.start_at"
    @click="handleClickViewEvent"
    class="w-full flex justify-center sticky cursor-pointer items-center gap-1 py-2 border-b border-untitled-gray-200 bg-untitled-gray-200"
    :class="[dialogStore.isDialogVisible ? 'z-40' : 'z-[4000]']"
  >
    <div class="text-sm text-untitled-gray-600 font-semibold animate-pulse">
      {{ event.name }}
    </div>
    <span class="mx-1 text-untitled-gray-600 animate-pulse">|</span>
    <CalendarIcon class="text-untitled-gray-600 w-6 animate-pulse" />
    <div class="text-sm text-untitled-gray-600 font-semibold animate-pulse">
      {{ dateFormat(event.start_at) }}
    </div>
  </div>
</template>

<script setup>
import { CalendarIcon } from "@heroicons/vue/solid";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { dateFormat } from "@/helpers/date_formatter";
import { http } from "@/composable/httpService";
import { useDialogStore } from "@/stores/useDialogStore";
import { useEventBannerStore } from "@/stores/eventBanner.store";
import { storeToRefs } from "pinia";

const router = useRouter();
const dialogStore = useDialogStore();
const eventBannerStore = useEventBannerStore();
const { event: eventBanner } = storeToRefs(eventBannerStore);
const event = ref(null);

const handleClickViewEvent = () => {
  router.push({ name: "event-detail-page", params: { id: event.value.id } });
};

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getUpcomingEvent = async () => {
  const { data } = await http().get(
    `/v3/events/featured-event?timezone=${userTimeZone}`
  );
  event.value = data.data;
  eventBanner.value = data.data;
};

onMounted(() => {
  getUpcomingEvent();
});
</script>

<style lang="scss" scoped></style>
